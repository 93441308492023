import axios from 'axios';
import { SessionActionModalType, SessionModalStatus, SessionStatus } from 'common-types';
import { ConfirmationModal } from 'components/booking/components/confirmation';
import { AddSessionModal } from 'components/modals/booking/add-session';
import { EditSessionModal } from 'components/modals/booking/edit-session';
import { SessionStatusModal } from 'components/modals/booking/session-status-modal';
import { getUnixTime } from 'date-fns';
import { useGetLastUserSession } from 'hooks/use-get-last-user-session';
import { useGetUserData } from 'hooks/use-get-user-data';
import { memo, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAnalytics } from 'services/analytics';
import { useStore } from 'store';
import { SUPPORT_TOKEN } from 'utils';
import { shallow } from 'zustand/shallow';

import { analyticModalMessage } from './data';

export const SessionWatcher = memo((): JSX.Element => {
  const { trackEvent } = useAnalytics();
  // const queryClient = useQueryClient();
  const { lastUserSession, lastUserSessionTimestamp, lastUserSessionStatus, isNextSessionInPast } =
    useGetLastUserSession();
  const { userName, userEmail, isSubscriptionExpired } = useGetUserData();
  // const { mutateAsync: addNewSessionAsync } = useSetNewMeet();

  const {
    sessionModal,
    sessionActionModal,
    isConfirmModalOpen,
    updateSessionModal,
    updateSessionActionModal,
    closeSessionModal,
    closeSessionActionModal,
  } = useStore(
    (state) => ({
      sessionModal: state.sessionModal,
      sessionActionModal: state.sessionActionModal,
      isConfirmModalOpen: state.isConfirmModalOpen,
      updateSessionModal: state.updateSessionModal,
      closeSessionModal: state.closeSessionModal,
      updateSessionActionModal: state.updateSessionActionModal,
      closeSessionActionModal: state.closeSessionActionModal,
    }),
    shallow,
  );

  const createTicketToZendesk = useCallback(async () => {
    await axios.post(
      'https://rdsupportteam.zendesk.com/api/v2/tickets',
      {
        ticket: {
          subject: 'LUMI Limit session request',
          brand_id: '360004513657',
          ticket_form_id: '18671500303505',
          status: 'new',
          group_id: '16492132653841',
          priority: 'normal',
          description: `
          Name: ${userName}
          Email: ${userEmail}
          Message: I'm trying to book a session but I'm reaching the limit. Help me.
        `,
          requester: {
            name: userName ?? '',
            email: userEmail,
          },
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: SUPPORT_TOKEN,
        },
      },
    );

    toast.success('Your message has been sent to our support team');
  }, [userEmail, userName]);

  // const { isModalAlreadyShown, updateIsModalAlreadyShown } = useLocalStorageStore(
  //   (state) => ({
  //     isModalAlreadyShown: state.isModalAlreadyShown,
  //     updateIsModalAlreadyShown: state.updateIsModalAlreadyShown,
  //   }),
  //   shallow,
  // );

  // const { isPrevSessionCanceled } = useSessionStorageStore((state) => ({
  //   isPrevSessionCanceled: state.isPrevSessionCanceled,
  // }));

  const { isOpen: isSessionModalOpen, type: activeSessionModalType } = sessionModal;
  const { isOpen: isSessionActionModalOpen, type: activeSessionActionModalType } = sessionActionModal;

  const isShowSessionActionModal = !isSubscriptionExpired && isSessionActionModalOpen;
  const isShowSessionModal = !isSubscriptionExpired && isSessionModalOpen;

  const isSessionStartsInLessThan10Minutes = lastUserSessionTimestamp
    ? lastUserSessionTimestamp - getUnixTime(Date.now()) < 600
    : false;

  const handleCloseSessionModal = useCallback((): void => {
    closeSessionModal();
  }, [closeSessionModal]);

  // const handleOpenBookingModal = useCallback((): void => {
  //   updateIsModalAlreadyShown(true);
  //   closeSessionModal();
  //   updateSessionActionModal({ isOpen: true, type: SessionActionModalType.ADD });
  // }, [closeSessionModal, updateIsModalAlreadyShown, updateSessionActionModal]);

  const handleOpenSessionModal = useCallback((): void => {
    handleCloseSessionModal();

    if (isNextSessionInPast) {
      updateSessionActionModal({ isOpen: true, type: SessionActionModalType.ADD });
    } else {
      updateSessionActionModal({ isOpen: true, type: SessionActionModalType.EDIT });
    }
  }, [handleCloseSessionModal, isNextSessionInPast, updateSessionActionModal]);

  const handleCloseActionSession = useCallback((): void => {
    closeSessionActionModal();
  }, [closeSessionActionModal]);

  const openStylistChat = useCallback((): void => {
    // @ts-ignore
    window.zE('messenger', 'open');

    closeSessionModal();
  }, [closeSessionModal]);

  // const maybeLaterClick = useCallback((): void => {
  //   trackEvent('session_popup_button_click', { button: 'maybe_later' });
  //   updateIsModalAlreadyShown(true);
  //   closeSessionModal();
  // }, [closeSessionModal, trackEvent, updateIsModalAlreadyShown]);

  const almostHereClick = useCallback((): void => {
    trackEvent('session_popup_button_click', { button: 'ok' });
    closeSessionModal();
  }, [closeSessionModal, trackEvent]);

  const alreadyStartedClick = useCallback((): void => {
    trackEvent('session_popup_button_click', { button: 'already_started' });
    closeSessionModal();
  }, [closeSessionModal, trackEvent]);

  const rescheduleClick = useCallback((): void => {
    trackEvent('session_popup_button_click', { button: 'reschedule' });
    handleOpenSessionModal();
  }, [handleOpenSessionModal, trackEvent]);

  const joinClick = useCallback((): void => {
    trackEvent('session_popup_button_click', { button: 'join' });
    openStylistChat();
  }, [openStylistChat, trackEvent]);

  useEffect(() => {
    if (
      isSessionStartsInLessThan10Minutes &&
      !isNextSessionInPast &&
      lastUserSessionStatus !== SessionStatus.CANCELED
    ) {
      updateSessionModal({ isOpen: true, type: SessionModalStatus.START_SOON });
    }

    if (lastUserSessionStatus === SessionStatus.NO_SHOW) {
      updateSessionModal({ isOpen: true, type: SessionModalStatus.TIME_OUT });
    }
  }, [
    // bookNewSession,
    isNextSessionInPast,
    // isPrevSessionCanceled,
    isSessionStartsInLessThan10Minutes,
    lastUserSession,
    lastUserSessionStatus,
    updateSessionModal,
  ]);

  useEffect(() => {
    if (isSessionModalOpen) {
      trackEvent('session_popup_shown', { popup_message: analyticModalMessage[activeSessionModalType] });
    }
  }, [activeSessionModalType, isSessionModalOpen, trackEvent]);

  return (
    <>
      <AddSessionModal
        isOpen={isShowSessionActionModal && activeSessionActionModalType === SessionActionModalType.ADD}
        handleClose={handleCloseActionSession}
      />
      <EditSessionModal
        isOpen={isShowSessionActionModal && activeSessionActionModalType === SessionActionModalType.EDIT}
        handleClose={handleCloseActionSession}
      />
      {/* <SessionStatusModal
        type={SessionModalStatus.TIME_OUT}
        handleClose={handleCloseSessionModal}
        isOpen={isShowSessionModal && !isModalAlreadyShown && activeSessionModalType === SessionModalStatus.TIME_OUT}
        handleConfirmationButtonClick={handleOpenBookingModal}
        handleSecondaryButtonClick={maybeLaterClick}
      /> */}
      <SessionStatusModal
        type={SessionModalStatus.REACH_LIMIT}
        handleClose={handleCloseSessionModal}
        isOpen={isShowSessionModal && activeSessionModalType === SessionModalStatus.REACH_LIMIT}
        handleConfirmationButtonClick={rescheduleClick}
        handleSecondaryButtonClick={createTicketToZendesk}
      />
      <SessionStatusModal
        type={SessionModalStatus.SCHEDULED}
        handleClose={handleCloseSessionModal}
        isOpen={isShowSessionModal && activeSessionModalType === SessionModalStatus.SCHEDULED}
        handleConfirmationButtonClick={almostHereClick}
        handleSecondaryButtonClick={null}
      />
      <SessionStatusModal
        type={SessionModalStatus.ALREADY_STARTED}
        handleClose={handleCloseSessionModal}
        isOpen={isShowSessionModal && activeSessionModalType === SessionModalStatus.ALREADY_STARTED}
        handleConfirmationButtonClick={alreadyStartedClick}
        handleSecondaryButtonClick={null}
      />
      <SessionStatusModal
        type={SessionModalStatus.START_SOON}
        handleClose={handleCloseSessionModal}
        isOpen={isShowSessionModal && activeSessionModalType === SessionModalStatus.START_SOON}
        handleConfirmationButtonClick={joinClick}
        handleSecondaryButtonClick={handleOpenSessionModal}
      />
      <ConfirmationModal isOpen={isConfirmModalOpen} />
    </>
  );
});
